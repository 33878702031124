<template>
  <div>
    <div
      v-if="!transactions.length"
      class="text-white text-center whitespace-nowrap p-8"
    >
      <p class="grid h-32 place-items-center">
        No transactions found.
      </p>
    </div>
    <div
      v-else
      class="lg:flex min-h-screen"
    >
      <div class="basis-2/3">
        <BudgetTable
          :transactions="transactions"
          :budgets="budgets"
        />
      </div>
      <div class="basis-1/3 bg-neutral-200 dark:bg-neutral-800">
        <div class="sticky top-0 z-90">
          <TransactionPieChart
            :transactions="transactions"
          />
          <AccountBalance
            :transactions="transactions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionPieChart from '@/app/views/transactions/charts/transaction-pie-chart.vue'
import AccountBalance from '@/app/views/transactions/charts/account-balance.vue'
import BudgetTable from '@/app/views/transactions/charts/budget-table.vue'
import Budget from '@/structs/budget.js'

export default {
  components: {
    AccountBalance,
    BudgetTable,
    TransactionPieChart
  },

  props: ['app', 'sheet', 'transactions'],

  data () {
    return {
      budgets: []
    }
  },

  watch: {
    transactions () {
      this.$nextTick(() => this.updateBudgets())
    }
  },

  mounted () {
    this.updateBudgets()
  },

  methods: {
    updateBudgets () {
      const postings = this.transactions.reduce((acc, t) => acc.concat(t.postings), [])
      const budgets = postings
        .filter((p, i) => i % 2 === 0)
        .map((p) => Budget.fromPosting(p))

      budgets.forEach((budget) => budget.applyTransactions(this.transactions.slice(0, this.transactions.length / 1.5)))

      this.budgets = budgets
    }
  }
}
</script>
