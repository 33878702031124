<template>
  <div>
    <div
      v-if="!transactions.length"
      class="text-white text-center whitespace-nowrap p-8"
    >
      <p class="grid h-32 place-items-center">
        No transactions found.
      </p>
    </div>
    <div
      v-else
      class="lg:flex min-h-screen"
    >
      <div class="basis-1/3 bg-neutral-100 dark:bg-neutral-800 p-3 lg:p-0">
        <div class="z-40">
          <TransactionPieChart
            :transactions="transactions"
          />
          <AccountBalance
            :transactions="transactions"
          />
        </div>
      </div>
      <div class="basis-2/3 w-full lg:w-auto bg-white dark:bg-none">
        <MonthlyAccountGraph
          :date="sheet.dateFilter.value"
          :transactions="transactions"
          class="p-5"
        />
        <AnnualAccountGraph
          v-if="isAnnual"
          :date="sheet.dateFilter.value"
          :transactions="transactions"
          class="p-5"
        />
        <CumulativeAccountGraph
          v-if="isAnnual || isAllTime"
          :transactions="transactions"
          class="p-5"
        />
        <Register
          v-if="isMonthly"
          :app="app"
          :sheet="sheet"
          :transactions="transactions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TransactionPieChart from '@/app/views/transactions/charts/transaction-pie-chart.vue'
import AnnualAccountGraph from '@/app/views/transactions/charts/annual-account-graph.vue'
import AccountBalance from '@/app/views/transactions/charts/account-balance.vue'
import CumulativeAccountGraph from '@/app/views/transactions/charts/cumulative-account-graph.vue'
import MonthlyAccountGraph from '@/app/views/transactions/charts/monthly-account-graph.vue'
import Register from '@/app/views/transactions/types/register.vue'

export default {
  components: {
    AccountBalance,
    AnnualAccountGraph,
    CumulativeAccountGraph,
    MonthlyAccountGraph,
    TransactionPieChart,
    Register
  },
  props: ['app', 'sheet', 'transactions'],
  computed: {
    isMonthly () {
      return this.sheet.dateFilter.toString().indexOf('month') !== -1
    },

    isAnnual () {
      return this.sheet.dateFilter.toString().indexOf('year') !== -1
    },

    isAllTime () {
      return !this.isMonthly && !this.isAnnual
    }
  }
}
</script>
