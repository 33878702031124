<template>
  <div ref="chartWrapper" />
</template>

<script>
import { Chart } from 'chart.js'
import { SankeyController, Flow } from 'chartjs-chart-sankey'

Chart.register(SankeyController, Flow)

export default {
  props: ['transactions'],
  computed: {
    chartData () {
      const data = []

      this.transactions.forEach((transaction) => {
        const elided = transaction._original.postings.find((p) => p.elided)

        if (!elided) return

        transaction.postings.filter((p) => p !== elided).forEach((posting) => {
          const from = posting.amount > 0 ? elided.account : posting.account
          const to = posting.amount > 0 ? posting.account : elided.account

          const found = data.find((d) => d.from === from && d.to === to)

          if (found) {
            found.flow += Math.abs(posting.amount)
          } else {
            data.push({ from, to, flow: Math.abs(posting.amount) })
          }
        })
      })

      return {
        datasets: [{
          data,
          color: 'rgba(255, 255, 255, 0.8)',
          colorFrom: (c) => 'rgb(74, 222, 128)',
          colorTo: (c) => 'rgb(99, 102, 241)',
          colorMode: 'gradient', // or 'from' or 'to'
          size: 'max' // or 'min' if flow overlap is preferred
        }]
      }
    }
  },
  watch: {
    chartData () {
      this.$nextTick(() => this.makeChart())
    }
  },
  mounted () {
    this.makeChart()
  },
  methods: {
    makeChart () {
      const data = this.chartData
      const canvas = document.createElement('canvas')

      canvas.className = 'block p-4'

      this.$refs.chartWrapper.innerHTML = ''
      this.$refs.chartWrapper.appendChild(canvas)

      Chart.defaults.font.color = 'white'

      new Chart(canvas, { /* eslint-disable-line no-new */
        type: 'sankey',
        data,
        options: {
          responsive: true,
          animation: false
        }
      })
    }
  }
}
</script>
