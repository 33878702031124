<template>
  <Panel
    v-if="transaction"
    :back="{ name: 'transactions', params: { identity_id: app.identity.id } }"
  >
    <template #content>
      <div
        id="transaction-invoice"
        class="print:text-sm"
      >
        <button
          class="float-left print:hidden rounded-md bg-indigo-500 px-3 py-2 text-sm text-white"
          @click="print"
        >
          Print
        </button>

        <p class="text-right">
          <strong>INVOICE</strong><br><br>
          <span v-html="app.queries.toAccountAddress(app.identity, transaction)" />
        </p>

        <p>
          <span v-if="transaction.referenceId">
            Invoice No: {{ transaction.referenceId }}<br>
          </span>
          Date: {{ app.queries.prettyDate(transaction.date) }}<br>
        </p>

        <br>

        <p
          v-if="app.queries.transactionCleared(transaction)"
          class="float-right"
        >
          PAID
        </p>

        <p>
          <strong>Bill To:</strong><br>
          <span v-html="app.queries.fullPayeeAddress(app.identity, transaction)" />
        </p>

        <br><br>

        <table class="w-full">
          <thead>
            <tr>
              <th class="text-left">
                Items & Description
              </th>
              <th class="text-right">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="posting in app.queries.income(transaction.postings)"
              :key="posting"
            >
              <td v-html="posting.comment || app.queries.fullAccountName(app.identity, posting.account)" />
              <td class="text-right">
                {{ app.queries.formatAmount(-posting.amount, posting.currency) }}
              </td>
            </tr>
            <tr>
              <th colspan="2">
                <br>
              </th>
            </tr>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td class="text-right">
                <strong>{{ app.queries.transactionTotal(transaction) }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'

export default {
  components: {
    Panel
  },

  props: ['app'],

  computed: {
    transaction () {
      return this.app.queries.findTransactionForIdentity(this.app.identity.id, this.$route.params.transaction_id)
    }
  },

  methods: {
    print () {
      window.print()
    }
  }
}
</script>

<style lang="scss">
  @media print {
    .main-content {
      display: none
    }
  }
</style>
