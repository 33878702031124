<template>
  <table class="w-full divide-y divide-neutral-200 dark:divide-neutral-800">
    <thead>
      <tr>
        <th class="py-3.5 pl-4 pr-3 text-left">
          Account
        </th>
        <th class="px-3 py-3.5 text-left">
          Amount
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-neutral-200 dark:divide-neutral-800">
      <tr
        v-for="details in accountDetails"
        :key="details"
      >
        <td class="whitespace-nowrap py-2 px-3 pl-8 truncate max-w-64 lg:text-clip lg:max-w-none">
          {{ details.name }}
        </td>
        <td class="whitespace-nowrap py-2 px-3 pr-8">
          <ProgressBar
            :title="queries.formatAmount(details.amount, details.currency)"
            :amount="Math.abs(details.amount)"
            :max="maxAmount"
            :always-green="details.amount >= 0"
            :always-red="details.amount < 0"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Queries from '@/queries/index.js'
import { postingsForAccount, sortByAmountExtremes } from '@/parse-and-play/utils.js'
import ProgressBar from '@/app/components/progress-bar/component.vue'

export default {
  components: {
    ProgressBar
  },

  props: {
    transactions: {
      type: Array,
      default () { return [] }
    },

    queries: {
      type: Object,
      default () { return new Queries() }
    }
  },

  computed: {
    postings () {
      return this.transactions.reduce((acc, t) => acc.concat(t.postings), [])
    },

    currencies () {
      return this.transactions.reduce((arr, transaction) => {
        transaction.postings.forEach((posting) => {
          if (arr.indexOf(posting.currency) === -1) {
            arr.push(posting.currency)
          }
        })

        return arr
      }, [])
    },

    accountNames () {
      return [...new Set(this.postings.map((p) => p.account))].sort()
    },

    accountDetails () {
      const lines = []

      this.currencies.forEach((currency) => {
        this.accountNames.forEach((name) => {
          const postings = postingsForAccount(this.postings, name)
            .filter((posting) => posting.currency === currency)

          const amount = postings.reduce((acc, posting) => acc + posting.amount, 0)

          if (!postings.length) {
            return
          }

          lines.push({
            name,
            currency,
            amount
          })
        })
      })

      return lines.sort(sortByAmountExtremes)
    },

    maxAmount () {
      let max = 1

      this.accountDetails.forEach((details) => {
        if (Math.abs(details.amount) > max) {
          max = Math.abs(details.amount)
        }
      })

      return max
    }
  }
}
</script>
