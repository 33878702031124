<template>
  <Panel
    :title="app.queries.getSourceName(source)"
    :back="{ name: 'sources', params: { identity_id: app.identity.id } }"
  >
    <template #content>
      <p class="my-5">
        <router-link :to="{ name: 'sources', params: { identity_id: app.identity.id } }">
          &larr; Back to Data Sources
        </router-link>
      </p>

      <p class="my-5">
        Data sources provide the data (eg. transactions, categories) for an identity.
        A data source can come in many different forms, such as: a bank, a brokerage account, a plain text accounting file on an S3 bucket, a file upload.
      </p>

      <form
        class="mt-5"
        @submit.prevent="save"
      >
        <div class="col-span-full mb-3">
          <label>How would you like to import your data?</label>
          <select
            v-model="newData.type"
            class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
          >
            <option value="DunderMifflin">
              Demo (Dunder Mifflin)
            </option>
            <option>Git</option>
            <option>S3</option>
            <!-- <option>Upload</option> -->
            <option>Url</option>
          </select>
        </div>

        <div class="col-span-full mb-3">
          <label>How would you like to process your data?</label>
          <select
            v-model="newData.processor"
            class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
          >
            <option value="PlainText">
              Plain Text Accounting
            </option>
            <option value="PlainTextRandomizer">
              Plain Text Accounting (randomized values)
            </option>
          </select>
        </div>

        <div v-if="newData.type === 'Url'">
          <div class="col-span-full mb-3">
            <label>URL</label>
            <input
              v-model="newData.config.url"
              type="text"
              placeholder="https://example.com/accounting.txt"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
        </div>

        <div
          v-if="newData.type === 'Upload'"
          class="field"
        >
          <label>Upload a file</label>
          <input
            ref="upload"
            type="file"
            multiple
            class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            @change="upload"
          >
        </div>

        <div v-else-if="newData.type === 'S3'">
          <div class="col-span-full mb-3">
            <label>Endpoint</label>
            <input
              v-model="newData.config.endpoint"
              type="text"
              :placeholder="s3Defaults.endpoint"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Region</label>
            <input
              v-model="newData.config.region"
              type="text"
              :placeholder="s3Defaults.region"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Key</label>
            <input
              v-model="newData.config.filename"
              type="text"
              :placeholder="s3Defaults.filename"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Access Key ID</label>
            <input
              v-model="newData.config.accessKeyId"
              type="text"
              required="true"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Secret Access Key</label>
            <input
              v-model="newData.config.secretAccessKey"
              type="password"
              required="true"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Bucket</label>
            <input
              v-model="newData.config.bucket"
              type="text"
              required="true"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
        </div>

        <div v-else-if="newData.type === 'Git'">
          <div class="col-span-full mb-3">
            <label>Git URL</label>
            <input
              v-model="newData.config.url"
              type="text"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Git Branch</label>
            <input
              v-model="newData.config.branch"
              type="text"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Filename</label>
            <input
              v-model="newData.config.filename"
              type="text"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Username</label>
            <input
              v-model="newData.config.username"
              type="text"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
          <div class="col-span-full mb-3">
            <label>Password</label>
            <input
              v-model="newData.config.password"
              type="password"
              class="block w-full rounded-md border-0 bg-white/5 py-1.5 mt-1 text-white"
            >
          </div>
        </div>

        <button
          type="submit"
          class="block w-full rounded-md bg-indigo-500 px-3 py-2 text-white mt-5"
        >
          Save source
        </button>
      </form>

      <a
        href="javascript:;"
        class="block w-full text-center text-xs text-red-600 hover:text-red-400 mt-7"
        @click="deleteSource"
      >delete this source</a>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'
import S3 from '@/adapters/fetchers/s3.js'

export default {
  components: {
    Panel
  },

  props: ['app'],

  data () {
    return {
      newData: { config: {} },
      s3Defaults: S3.DEFAULTS
    }
  },

  computed: {
    source () {
      return this.app.queries.findSourceForIdentity(this.app.identity.id, this.$route.params.source_id)
    }
  },

  watch: {
    source () {
      this.setNewData()
    }
  },

  mounted () {
    this.setNewData()
  },

  methods: {
    deleteSource () {
      if (confirm('Are you sure you want to delete this source?')) {
        this.app.commands.removeSourceForIdentity(this.app.identity, this.source)
        this.app.commands.refreshIdentitySource(this.app.identity, this.source)
        this.$router.push({ name: 'sources', params: { identity_id: this.app.identity.id } })
      }
    },

    save () {
      this.app.commands.saveSourceForIdentity(this.app.identity, this.source, this.newData)
      this.app.commands.refreshIdentitySource(this.app.identity, this.source)
      this.app.refreshIdentitySources(this.app.identity)
      this.$router.push({ name: 'sources', params: { identity_id: this.app.identity.id } })
    },

    setNewData () {
      this.newData = Object.assign({}, JSON.parse(JSON.stringify(this.source || {})))
      this.newData.type = this.newData.type || 'S3'
      this.newData.config = this.newData.config || {}
    }
  }
}
</script>
