<template>
  <div
    v-if="app.identity"
    class="font-mono flex flex-col h-screen bg-neutral-100 dark:bg-neutral-900"
  >
    <div class="main-content">
      <header class="bg-neutral-200 dark:bg-neutral-950 border-b border-neutral-800">
        <div class="mx-auto">
          <div class="flex px-3">
            <div class="flex-none ml-3 py-4">
              <Menu
                :app="app"
              />
              <router-link
                :to="{ name: 'settings', params: { identity_id: app.identity.id } }"
                class="inline-block ml-3"
              >
                <span class="text-neutral-700 dark:text-white font-semibold inline-block text-lg">
                  {{ app.identity.name }}
                </span>
              </router-link>
              <router-link
                :to="{ name: 'sources', params: { identity_id: app.identity.id } }"
                :class="`inline-block rounded-full ml-2 -mb-0.5 p-1 ${app.sourceLoadingClass(sourcesLoading)}`"
              >
                <div class="h-2.5 w-2.5 rounded-full bg-current" />
              </router-link>
            </div>
            <div class="grow" />
            <!-- <a
              href="javascript:;"
              class="flex-none block text-neutral-400 mr-3 py-4"
            >
              Nil
            </a> -->
          </div>
          <nav
            class="w-100 pl-3 flex"
          >
            <div
              v-if="selectedSheet"
            >
              <Sortable
                item-key="id"
                :items="sheets"
                :on-change="saveSheetOrder"
              >
                <template #item="{ item }">
                  <SheetButton
                    :sheet="item"
                    :is-selected="selectedSheet === item"
                    :select-sheet="selectSheet"
                    :remove-sheet="removeSheet"
                    :update-sheet-name="updateSheetName"
                    :update-sheet-type="updateSheetType"
                  >
                    {{ item.name }}
                  </SheetButton>
                </template>
              </Sortable>
              <a
                href="javascript:;"
                class="text-neutral-400 border border-transparent border-b-0 hover:bg-neutral-900 hover:text-white inline-flex items-center rounded-t-lg py-2 px-3"
                @click="addSheet()"
              >+</a>
            </div>
            <p
              v-else
              class="text-neutral-400 mr-1 border border-transparent border-b-0 hover:bg-neutral-900 hover:text-white inline-flex items-center rounded-t-lg py-2 px-3"
            >
              Loading...
            </p>
          </nav>
        </div>
      </header>

      <Search
        v-if="selectedSheet"
        :sheet="selectedSheet"
        :account-names="accountNames"
        :payee-names="payeeNames"
        :update-sheet-filters="updateSheetFilters"
        :update-sheet-date-period="updateSheetDatePeriod"
        :update-sheet-date-value="updateSheetDateValue"
      />
    </div>

    <div class="main-content flex-1 overflow-y-auto text-sm bg-white dark:bg-neutral-950">
      <component
        :is="selectedSheetType"
        v-if="selectedSheet"
        :app="app"
        :sheet="selectedSheet"
        :transactions="transactions"
      />
    </div>

    <router-view
      :app="app"
    />
  </div>
</template>

<script>
import Menu from '@/app/components/menu/component.vue'
import assets from './types/assets.vue'
import budget from './types/budget.vue'
import account from './types/account.vue'
import register from './types/register.vue'
import sankey from './types/sankey.vue'
import Search from '@/app/components/search/component.vue'
import SheetButton from '@/app/components/sheet-button/component.vue'
import Transaction from '@/parse-and-play/transaction.js'
import { Filter, TypeFilter, DateFilter } from '@/structs/filter.js'
import Sortable from '@/app/components/sortable/component.vue'

export default {
  components: {
    Menu,
    assets,
    budget,
    account,
    register,
    sankey,
    Search,
    SheetButton,
    Sortable
  },

  props: ['app'],

  data () {
    return {
      selectedSheet: null,
      filterString: ''
    }
  },

  computed: {
    sheets () {
      return this.app.queries.findAllSheets(this.app.identity.id)
    },

    accountNames () {
      const postings = this.allTransactions.reduce((acc, t) => acc.concat(t.postings), [])
      return [...new Set(postings.map((p) => p.account))].sort()
    },

    payeeNames () {
      const payees = this.allTransactions.reduce((acc, t) => acc.concat([t.payee]), [])
      return [...new Set(payees)].sort()
    },

    allTransactions () {
      return this.app.queries.findAllTransactions(this.app.identity.id)
    },

    transactions () {
      return this.applyFilters(this.allTransactions)
    },

    selectedSheetType () {
      return this.selectedSheet.typeFilter.value
    },

    sourcesLoading () {
      const sources = this.app.queries.findAllSources(this.app.identity.id)

      return sources.filter((s) => s.isLoading).length > 0
    }
  },

  watch: {
    'app.identity' () {
      this.selectSheet(this.sheets[0])
    },

    'selectedSheet.dateFilter.value' () {
      this.app.commands.regeneratePeriodicTransactions(this.app.identity, this.selectedSheet.dateFilter)
    }
  },

  async mounted () {
    await this.bootstrap()
  },

  methods: {
    async bootstrap () {
      try {
        await this.app.commands.addSeedSheetIfRequired(this.app.identity)
        await this.app.refreshIdentitySources(this.app.identity, false)
        this.selectSheet(this.sheets[0])
      } catch (e) {
        console.error(e)
        this.app.showException(e)
      }
    },

    selectSheet (sheet) {
      this.app.commands.rebuildPeriodicTransactionsForFilters(this.app.identity, sheet.dateFilter)
      this.selectedSheet = sheet
    },

    addSheet () {
      this.app.commands.addSheetForIdentity(this.app.identity, {
        name: `Sheet #${this.sheets.length + 1}`,
        typeFilter: new TypeFilter('register'),
        dateFilter: DateFilter.all(),
        filters: []
      })

      this.selectedSheet = this.sheets[this.sheets.length - 1]
    },

    removeSheet (sheet) {
      if (confirm(`Are you sure you want to delete "${sheet.name}"?`)) {
        this.app.commands.removeSheetForIdentity(this.app.identity, sheet)
        this.selectSheet(this.sheets[0])
      }
    },

    updateSheetName (sheet, name) {
      this.app.commands.saveSheetForIdentity(this.app.identity, sheet, { name })
    },

    updateSheetType (sheet, newType) {
      const typeFilter = new TypeFilter(newType)

      this.app.commands.saveSheetForIdentity(this.app.identity, sheet, { typeFilter })
    },

    updateSheetDatePeriod (sheet, newDatePeriod) {
      let dateFilter

      if (newDatePeriod === DateFilter.PERIOD.ALL) {
        dateFilter = DateFilter.all()
      } else if (newDatePeriod === DateFilter.PERIOD.MONTH) {
        dateFilter = DateFilter.thisMonth()
      } else if (newDatePeriod === DateFilter.PERIOD.YEAR) {
        dateFilter = DateFilter.thisYear()
      }

      this.app.commands.saveSheetForIdentity(this.app.identity, sheet, { dateFilter })
    },

    updateSheetDateValue (sheet, value) {
      const dateFilter = sheet.dateFilter

      dateFilter.value = value

      this.app.commands.saveSheetForIdentity(this.app.identity, sheet, { dateFilter })
    },

    updateSheetFilters (sheet, rawFilters) {
      const matches = [...rawFilters.matchAll(/(\w+)[:=]"([^"]+)"/g, (a, b, splat) => splat)]
      const filters = matches.reduce((acc, splat) => {
        const filter = Filter.fromObj({ type: splat[1], value: splat[2] })
        acc.push(filter)
        return acc
      }, [])

      this.app.commands.saveSheetForIdentity(this.app.identity, sheet, { filters })
    },

    saveSheetOrder (ids) {
      this.app.commands.saveSheetOrderForIdentity(this.app.identity, ids)
    },

    applyFilters (transactions) {
      const transactionFilters = this.selectedSheet.transactionFilters
      const postingFilters = this.selectedSheet.postingFilters

      for (var i = 0; i < transactionFilters.length; i++) {
        transactions = transactions.filter(transactionFilters[i].filter())
      }

      return transactions.map((transaction) => {
        const t = new Transaction()

        t.id = transaction.id
        t.identityId = transaction.identityId
        t.date = transaction.date
        t.status = transaction.status
        t.referenceId = transaction.referenceId
        t.payee = transaction.payee
        t.postings = transaction.getPostings(postingFilters)
        t._original = transaction

        return t
      })
    }
  }
}
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  header, .no-print {
    display: none;
  }

  * {
    color: #000000;
  }
}
</style>
