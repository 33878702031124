import { clone } from 'isomorphic-git'
import FS from '@isomorphic-git/lightning-fs'
import http from 'isomorphic-git/http/web'
import { Buffer } from 'buffer'
import * as process from 'process'

window.global = window
window.process = process
window.Buffer = Buffer

const STRIP_BEGINNING_AND_END_SLASHES = /^\/|\/$/g

class Git {
  constructor (options) {
    this.options = options
    this.modes = ['read']
    this.fs = new FS(options.url.replace(/[^\w]/g, '-'))
  }

  get name () {
    return `Git (${this.options.url.replace(STRIP_BEGINNING_AND_END_SLASHES, '')}${this.options.filename})`
  }

  fetch () {
    const dir = '/data'
    const filename = this.options.filename.replace(STRIP_BEGINNING_AND_END_SLASHES, '')

    return new Promise((resolve, reject) => {
      clone({
        fs: this.fs,
        http,
        dir,
        ref: this.options.branch,
        url: this.options.url.replace(STRIP_BEGINNING_AND_END_SLASHES, ''),
        corsProxy: 'https://git-cors.dallasread.com',
        singleBranch: true,
        depth: 1,
        onAuth: (url) => {
          if (!this.options.username) return {}

          return {
            username: this.options.username,
            password: this.options.password
          }
        }
      })
        .then((git) => this.fs.promises.readFile([dir, filename].join('/')))
        .then((content) => resolve(content.toString()))
        .catch(reject)
    })
  }
}

export default Git
