<template>
  <div
    v-for="currency in currencies"
    :key="`account-balance-${currency}`"
    class="px-5 pb-5"
  >
    <pre class="mb-5 text-neutral-400"><span
    v-for="line in linesFor(currency)"
    :key="line"
    >{{ line }}<br></span>{{ totalFor(currency) }}</pre>
  </div>
</template>

<script>
import { pad, formatAmount, postingsForAccount, sumPostingsForCurrency } from '@/parse-and-play/utils'

export default {
  props: ['sheet', 'transactions'],

  computed: {
    postings () {
      return this.transactions.reduce((acc, t) => acc.concat(t.postings), [])
    },

    accounts () {
      const accs = [...new Set(this.postings.map((p) => p.account))].sort()
      const explodedAccounts = []

      for (var i = 0; i < accs.length; i++) {
        const splat = accs[i].split(':')

        for (var n = 0; n < splat.length; n++) {
          const fullAccount = splat.slice(0, n + 1).join(':')

          if (explodedAccounts.indexOf(fullAccount) === -1) {
            explodedAccounts.push(fullAccount)
          }
        }
      }

      return explodedAccounts
    },

    currencies () {
      return [...new Set(this.postings.map((p) => p.currency))]
    }
  },

  methods: {
    lastSplat (account) {
      const splat = account.split(':')

      return splat[splat.length - 1]
    },

    totalFor (currency) {
      const total = sumPostingsForCurrency(this.postings, currency)

      return `${pad('', 16, '-')}\n${pad(formatAmount(total, currency), 16)}`
    },

    linesFor (currency) {
      const lines = []

      for (var i = 0; i < this.accounts.length; i++) {
        const account = this.accounts[i]
        const postings = postingsForAccount(this.postings, account)
        const sum = sumPostingsForCurrency(postings, currency)
        const anyNestedAccountsWithAmounts = this.accounts
          .filter((a) => a.indexOf(`${account}:`) === 0)
          .map((a) => postingsForAccount(this.postings, a))
          .filter((p) => Math.abs(sumPostingsForCurrency(p, currency)).toLocaleString() !== '0')
          .length

        if (Math.abs(sum).toLocaleString() !== '0' || anyNestedAccountsWithAmounts) {
          const indent = (account.split(':').length - 1) * 1

          lines.push(`${pad(formatAmount(sum, currency), 16)} ${pad('', indent)}|- ${this.lastSplat(account)}`)
        }
      }

      return lines
    }
  }
}
</script>
